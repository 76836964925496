import React, { Component } from 'react';
import Image from 'gatsby-image';
import AspectRatio from 'react-aspect-ratio'
import styled from 'styled-components';
// import Anime from 'react-anime';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonial from '../components/testimonial'

const ImageGrid = styled.div`
  @media screen and (min-width:640px) {
    height: 398px;
  }
`


const numPrefix = (number) => {
  return number < 10 ? '0'+number : number;
}

const colours = ['blue-light', 'green', 'tan', 'tan', 'blue-light', 'green' ];

export default class Careers extends Component {
  render() {
    const { acf, yoast_meta } = this.props.data.page;
    const jobs = this.props.data.jobs;
    return (
      <Layout>
        <SEO yoast={yoast_meta} />

        {/* Hero */}
        <section className="careers-hero md:mb-32 sm:mb-20 mb-16 mt-4">
          <div className="o-wrapper" style={{maxWidth:'1329px'}}>
            <div className="relative md:pt-32">

              <AspectRatio ratio="1061/600" className="bg-tertiary rounded-sm hero-ratio-bg">
                <div>
                  <Image fluid={acf.hero.hero.image.localFile.childImageSharp.fluid} className="object-cover h-100 w-100" draggable={false} />
                </div>
              </AspectRatio>

              <div className="hidden md:block absolute top-0 right-0 w-full h-full flex flex-col justify-start">
                <AspectRatio ratio="949/654" className={`bg-${acf.hero.hero.background_colour || 'blue-light'} sm:pt-20 mr-0 ml-auto w-full h-full rounded-sm`} style={{maxHeight:'83%', maxWidth:'780px', zIndex:'-1'}}></AspectRatio>
              </div>

              <div className="md:absolute top-0 md:pt-16 right-0">
                <div className="md:py-12 py-8 md:px-10 bg-white inline-block md:mx-12 md:mb-12 rounded-sm">
                  <div className="text-left mx-auto block careers-hero-text">
                    <h1 className="u-h1 mb-6 fade-in-up">{acf.hero.hero.heading}</h1>
                    <p className="mb-6 fade-in-up ani-delay-200">{acf.hero.hero.content}</p>
                      {acf.hero.hero.buttons.map(({ button }) => {
                        if (button.url.slice(0,1) === '#') {
                          return (<a key={'hero'+button.title} href={button.url} className="fade-in-up ani-delay-400 w-full md:w-auto inline-block c-btn bg-black text-white hover:bg-green-light hover:text-black mt-2" dangerouslySetInnerHTML={{ __html: button.title }}/>)
                        } else {
                          return (<Link key={'hero'+button.title} to={button.url} className="fade-in-up ani-delay-400 w-full md:w-auto inline-block c-btn bg-black text-white hover:bg-green-light hover:text-black mt-2" dangerouslySetInnerHTML={{ __html: button.title }}/>)
                        }
                      })}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* What we do */}
        <section className="md:my-32 sm:my-20 my-16">
          <div className="o-wrapper">
            <header className="grid md:grid-cols-2 gap-6 md:mb-16 mb-10">
              <h2 className="u-h2">{acf.what_we_do.heading}</h2>
              <p>{acf.what_we_do.content}</p>
            </header>

            <ImageGrid className="flex flex-wrap -ml-2 -mt-6">
              {acf.what_we_do.images.map((img, i) =>
                <div key={`careers-${img+i}`} className={`flex-auto pl-2 ${i === 1 ? 'sm:w-6/12' : 'sm:w-3/12'} mt-6`}>
                  <img src={img} className="block h-full w-full object-cover" alt="" />
                </div>
              )}
            </ImageGrid>
          </div>
        </section>

        {/* Testimonial */}
        <Testimonial
          quote={acf.testimonial.quote}
          citeName={acf.testimonial.cite_name}
          citeRole={acf.testimonial.cite_role}
          image={acf.testimonial.image}
          colour={'blue-light'}
        />

        {/* What we do */}
        <section className="md:my-32 my-20">
          <div className="o-wrapper">
            <header className="md:mb-12 mb-10 max-w-md">
              <h2 className="u-h2 mb-6">{acf.our_values.heading}</h2>
              <p>{acf.our_values.content}</p>
            </header>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
              {acf.our_values.items.map((item, cnt) =>
                <div key={`careers-values-${item.heading+cnt}`} className={`px-6 py-10 bg-tertiary border-b-8 border-${colours[cnt]}`}>
                  <div className="u-h2 mb-6">{ numPrefix(cnt+1) }</div>
                  <h4 className="u-h4 mb-3" style={{fontSize:'32px'}}>{item.heading}</h4>
                  <div className="mb-3" dangerouslySetInnerHTML={{ __html:item.content }}/>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Our Office */}
        <section className="my-16 md:py-24 py-16 bg-tertiary">
          <div className="o-wrapper">
            <header className="md:mb-12 mb-10 max-w-md">
              <h2 className="u-h2 mb-6">{acf.our_office.heading}</h2>
              {/* <p>{acf.our_office.content}</p> */}
            </header>
            <div className="flex flex-wrap items-stretch">
              <div className="flex-auto lg:-mr-32 w-full lg:w-auto">
                <Image fluid={acf.our_office.image.localFile.childImageSharp.fluid} draggable={false} />
              </div>
              <div className="py-6 lg:py-12 lg:px-10 sm:px-8 px-6 bg-white lg:max-w-md w-full self-center relative">
                <div className="grid grid-cols-2 gap-6 mb-8">
                  <div>
                    <h4 className="u-h3 mb-1">{acf.our_office.address.heading}</h4>
                    <h5 className="">{acf.our_office.address.subheading}</h5>
                  </div>
                  <div>
                    <address className="not-italic" dangerouslySetInnerHTML={{ __html: acf.our_office.address.address }} />
                  </div>
                </div>
                <a href={acf.our_office.address.button.url} target={acf.our_office.address.button.target} className="c-btn bg-black text-white hover:bg-green-light hover:text-black transition duration-150 inline-block" style={{minWidth:'183px'}}>
                  {acf.our_office.address.button.title}
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* The Perks */}
        <section className="md:my-32 sm:my-20 my-16 text-center sm:text-left">
          <div className="o-wrapper">
            <header className="sm:mb-10 mb-8 max-w-md mx-auto sm:ml-0">
              <h2 className="u-h2 mb-6">{acf.the_perks.heading}</h2>
              <p>{acf.the_perks.content}</p>
            </header>

            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
              {acf.the_perks.items.map((item, cnt) =>
                <div className="sm:my-6 my-4" key={`perk-${cnt}`}>
                  { item.icon && item.icon.localFile && item.icon.localFile.publicURL && <img className="mx-auto sm:ml-0" src={ item.icon.localFile.publicURL } alt={ item.heading } /> }
                  <h4 className="u-h5 my-5">{item.heading}</h4>
                  <p>{item.content}</p>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Testimonial */}
        <Testimonial
          quote={acf.testimonial_2.quote}
          citeName={acf.testimonial_2.cite_name}
          citeRole={acf.testimonial_2.cite_role}
          image={acf.testimonial_2.image}
          colour={'tan'}
        />

        {/* Open Positions */}
        <section className="md:mt-32 mt-20 md:py-28 sm:py-20 py-16 bg-tertiary" id="open-positions">
          <div className="o-wrapper">
            <header className="mb-12 text-center">
              <h2 className="u-h2 mb-6">{acf.open_positions.heading}</h2>
              <p className="pb-1">{acf.open_positions.content}</p>
            </header>

            <main className="grid grid-cols-1 gap-6">
              {jobs.edges.map((item, cnt)=>
                <a
                  key={`careers-position-${cnt}`}
                  href={item.node.acf.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col sm:flex-row text-center sm:text-left p-8 justify-between items-center bg-white"
                  style={{boxShadow: '0 14px 10px 0 rgba(0,0,0,0.02)'}}
                >
                  <div className="mb-4 sm:mb-0">
                    <h4 className="font-semibold u-h4 sm:u-h5 sm:mb-2 mb-1">{item.node.title}</h4>
                    <p>{item.node.acf.location}</p>
                  </div>
                  <div className="flex items-center font-semibold">
                    Apply now
                    <img src={require('../images/circle-arrow.svg')} className={`ml-4 transform scale-75 sm:scale-100 rotate-90`} alt="" />
                  </div>
                </a>
              )}
            </main>
          </div>
        </section>



      </Layout>
    );
  }
}

export const query = graphql`
query Careers {
  jobs: allWordpressWpJobs {
    edges {
      node {
        title
        acf {
          location
          url
        }
      }
    }
  }
  page: wordpressPage(slug: {eq: "careers"}) {
    id
    ...Yoast
    acf {
      hero {
        ... Hero
      }
      what_we_do {
        heading
        content
        images
      }
      testimonial {
        quote
        cite_name
        cite_role
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      our_values {
        heading
        content
        items {
          heading
          content
        }
      }
      our_office {
        heading
        # content
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:880) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        address {
          heading
          subheading
          address
          button {
            title
            url
            target
          }
        }
      }
      the_perks {
        heading
        content
        items {
          heading
          content
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
      testimonial_2 {
        quote
        cite_name
        cite_role
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      open_positions {
        heading
        content
      }
    }
  }
}
`
