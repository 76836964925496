import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components'

const Quote = styled.div`

  @media(min-width:768px) {
    font-size:32px;
  }
`

const ImageContainer = styled.div`

@media screen and (minWidth:768px) {
  max-width:370px
}

`

class Testimonial extends Component {
  render() {
    return (
      <section className="testimonial md:my-32 my-20">
        <div className="o-wrapper">
          <div className={`p-4 sm:p-6 bg-${this.props.colour || 'blue-light'}`}>
            <div className="lg:p-10 md:p-8 bg-white text-center md:text-left">
              <div className="flex flex-col md:flex-row items-stretch -ml-12">
                <div className="pl-12 md:w-7/12 order-1 md:order-0  flex flex-col justify-center">
                  <div className="pt-6 pb-2 px-6 md:p-0">
                    <blockquote className="not-italic max-w-lg mx-auto py-6 sm:py-10 lg:px-6 md:mb-1">
                      <img src={require('../images/icons/quote.svg')} className="mx-auto md:ml-0" alt="Quote Icon" style={{maxWidth:'30px'}} />
                      <Quote className="sm:u-h4 u-h5 mt-5">{this.props.quote}</Quote>
                      <cite className="flex flex-col md:flex-row items-center not-italic mt-8">
                        <div className="text-16px mr-3 font-bold">{this.props.citeName}</div>
                        <div className="text-14px opacity-50">{this.props.citeRole}</div>
                      </cite>
                    </blockquote>
                  </div>
                </div>
                <div className="pl-12 md:w-5/12 block">
                  <ImageContainer className="h-full ">
                    <Image className="h-full mr-0 ml-auto" fluid={this.props.image.localFile.childImageSharp.fluid} draggable={false} />
                  </ImageContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  citeName: PropTypes.string,
  citeRole: PropTypes.string,
  image: PropTypes.object,
  colour: PropTypes.string
};

export default Testimonial;